<template>
  <div>
    <transition name="fade">
      <div
        v-if="$route.name === 'Location' || this.$store.state.showMenu"
        class="fixed inset-0 bg-black z-10 opacity-50"
        :class="{
          'pointer-events-none': !panelOpen,
          'cursor-sw-resize': panelOpen,
        }"
        @click="closePanels"
      />
    </transition>
    <div
      id="map-popup-notice"
      v-if="showPanelNotice"
      class="
        fixed
        bottom-0
        left-0
        right-0
        m-4
        z-20
        bg-white
        p-3
        rounded
        text-sm
        shadow-lg
        text-center
        cursor-pointer
        text-xs
        md:text-sm
        max-w-sm
      "
      @click="showPanelNotice = false"
    >
      <span class="mr-3 hidden md:inline">╳</span>
      <span
        >Tap on a circle to view an image<br class="md:hidden" /> (opens in a
        panel)<span class="md:hidden mt-2 block">OK</span></span
      >
    </div>
    <div id="map" class="w-screen h-screen" />
    <transition name="fade">
      <button
        aria-label="Reset map to original location"
        v-if="
          showReset &&
          !(this.$store.state.showMenu || this.$route.name === 'Location')
        "
        @click="resetMap"
        class="
          reset-map
          w-16
          h-16
          rounded-full
          fixed
          top-0
          left-0
          z-20
          bg-white
          m-6
          flex
          items-center
          justify-center
          shadow-lg
          cursor-pointer
        "
      >
        <div class="text-2xl">
          <svg
            width="20"
            height="23"
            viewBox="0 0 20 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 13C19 17.9706 14.9706 22 10 22C5.02944 22 1 17.9706 1 13C1 8.02944 5.02944 4 10 4"
              stroke="black"
            />
            <path d="M13 4L8.5 7.4641L8.5 0.535898L13 4Z" fill="black" />
          </svg>
        </div>
      </button>
    </transition>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import mapboxgl from 'mapbox-gl'

export default {
  name: 'Map',
  data() {
    return {
      map: null,
      showReset: false,
      startZoom: 16,
      startCenter: [-3.1750822, 55.9227405],
      resetting: false,
      baseMarkerColor: '#00ff00',
      baseMarkerOpacity: 0.9,
      showPanelNotice: true,
    }
  },
  mounted() {
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN

    this.map = new mapboxgl.Map({
      container: 'map', // container ID
      style: 'mapbox://styles/katie-paterson-ideas/cks9z41gc05co17nz1cr9kzkv', // style URL
      center: this.startCenter, // starting position [lng, lat]
      zoom: this.startZoom, // starting zoom
    })

    this.map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        // When active the map will receive updates to the device's location as it changes.
        trackUserLocation: true,
        // Draw an arrow next to the location dot to indicate which direction the device is heading.
        showUserHeading: true,
      })
    )

    this.map.addControl(new mapboxgl.NavigationControl())

    for (const marker of this.items) {
      // Create a DOM element for each marker.
      const el = document.createElement('button')
      const width = 18
      const height = 18
      el.id = `marker-${marker.id}`
      el.className = marker.indoors == 'true' ? 'marker indoors' : 'marker'
      el.style.width = `${width}px`
      el.style.height = `${height}px`
      el.style.background = this.baseMarkerColor
      el.style.borderRadius = '16px'
      el.style.cursor = 'pointer'
      el.ariaHasPopup = true
      el.ariaOwns = 'panel-' + marker.id
      el.title =
        'Click or tap to view more information in a popup for the artwork with the text ' +
        marker.title +
        ' ' +
        (marker.alt ? marker.alt : '')
      el.ariaLabel =
        'Click or tap to view more information in a popup for the artwork with the text ' +
        marker.title +
        ' ' +
        (marker.alt ? marker.alt : '')

      el.addEventListener('click', () => {
        if (this.$route.params.id !== marker.id) {
          this.$router.push({ name: 'Location', params: { id: marker.id } })
        }
      })

      // Add markers to the map.
      new mapboxgl.Marker(el).setLngLat(marker.location).addTo(this.map)
    }

    this.map.on('movestart', () => {
      this.$nextTick(() => {
        if (!this.resetting) {
          this.showReset = true
        } else {
          this.resetting = false
        }
      })
    })

    this.styleMarkers()
  },
  computed: {
    items() {
      return this.$store.state.items
    },
    showNav() {
      return !this.$store.state.showMenu && !this.$store.state.currentItem
    },
    panelOpen() {
      return this.$route.name === 'Location' || this.$store.state.showMenu
    },
    foundItems() {
      return this.$store.state.foundItems
    },
  },
  methods: {
    styleMarkers() {
      Array.from(document.querySelectorAll('.marker')).forEach((el) =>
        el.classList.remove('found')
      )

      if (this.foundItems.length) {
        this.foundItems.forEach((id) => {
          const el = document.getElementById(`marker-${id}`)
          if (el) {
            el.classList.add('found')
          }
        })
      }
    },
    closePanels() {
      this.$store.commit('MENU', false)

      if (this.$route.name !== 'Home') {
        this.$router.push('/')
      }
    },
    resetMap() {
      this.resetting = true
      this.showReset = false
      Array.from(document.querySelectorAll('.marker')).forEach((el) => {
        el.classList.remove('active')
        el.classList.remove('seeonmap')
      })
      this.map.flyTo({
        zoom: this.startZoom,
        speed: 0.6, // make the flying slow
        curve: 1,
        center: this.startCenter,
        essential: true,
      })
    },
  },
  watch: {
    $route() {
      Array.from(document.querySelectorAll('.marker')).forEach((el) => {
        el.classList.remove('active')
        el.classList.remove('seeonmap')
      })

      if (this.$route.name === 'Location') {
        document
          .getElementById(`marker-${this.$route.params.id}`)
          .classList.add('active')
      }

      // See on map no longer useful since you cannot navigate by list
      // if (
      //   this.$route.name === 'Home' &&
      //   this.$route.hash &&
      //   !['#about', '#your-journey'].includes(this.$route.hash)
      // ) {
      //   this.$store.commit('MENU', false)
      //   document
      //     .getElementById(`marker-${this.$route.hash.slice(1)}`)
      //     .classList.add('seeonmap')

      //   const location = this.$store.state.items.find(
      //     (x) => x.id === this.$route.hash.slice(1)
      //   )
      //   this.map.flyTo({
      //     zoom: 17,
      //     speed: 0.8, // make the flying slow
      //     curve: 1,
      //     center: location.location,
      //     essential: true,
      //   })
      // }
    },
    '$store.state.foundItems'() {
      this.styleMarkers()
    },
  },
}
</script>

<style lang="css">
@keyframes pulse {
  0% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}

.marker {
  opacity: 0.7;
  z-index: 10;

  &.found {
    background-color: rgba(0, 255, 0, 0.2) !important;
    border: 3px solid rgb(0, 255, 0) !important;
  }
  &.seeonmap {
    background-color: #ff0000 !important;
    animation: pulse 1.5s infinite;
    opacity: 1 !important;
    outline: 4px solid black;
    filter: drop-shadow(5px 5px 5px rgb(0, 0, 0, 0.3));
    animation-direction: alternate;
  }
  /* &.indoors {
    background: rgb(65, 97, 52) !important;
    &.found {
      background-color: rgba(0, 200, 0, 0.2) !important;
      border: 3px solid rgb(0, 200, 0) !important;
    }
  } */
  &.active,
  &:hover {
    opacity: 1;
    animation: pulse 1.5s infinite;
    animation-direction: alternate;
    background: black !important;
    filter: drop-shadow(5px 5px 5px rgb(0, 0, 0, 0.3));
  }
}

.reset-map {
  margin-top: 6.5em;
}

.mapboxgl-user-location-accuracy-circle,
.mapboxgl-user-location {
  z-index: 1;
  pointer-events: none;
}
</style>
