<template>
  <div>
    <Map v-if="items" />
    <transition :name="getTransition">
      <router-view />
    </transition>
  </div>
</template>

<script>
import Map from '@/components/Map'

export default {
  name: 'Home',
  components: {
    Map,
  },
  computed: {
    items() {
      return this.$store.state.items
    },
    getTransition() {
      if (this.$store.state.showMenu) {
        return 'fade'
      } else {
        return 'panel'
      }
    },
  },
}
</script>
